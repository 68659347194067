import request from "@/helper/request";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function storeOrder(data){
    return request({
        url: `/api/orders/store`,
        method: 'post',
        data
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getOrders(current_page, is_completed=0){
    return request({
        url: `api/orders?page=${current_page}&is_completed=${is_completed}`,
        method: 'get'
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function showOrder(order_id){
    return request({
        url:'/api/orders/show',
        method:'post',
        data: order_id
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deleteOrder(order_id){
    return request({
        url:'/api/orders/delete',
        method:'post',
        data: order_id
    })
}