<template>
    <div class="spinnerWrapper">
        <div class="spinner">
            <div class="rectangle"></div>
            <div class="rectangle"></div>
            <div class="rectangle"></div>
            <div class="rectangle"></div>
            <div class="rectangle"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loader'
}
</script>

<style>
@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% {
        -webkit-transform: scaleY(0.4)
    }
    20% {
        -webkit-transform: scaleY(1.0)
    }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}

.spinnerWrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
}

.spinner {
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
}

.rectangle {
    background-color: var(--primary-color);
    height: 100%;
    width: 6px;
    display: inline-block;
    margin: 0 2px;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.rectangle:nth-child(2) {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.rectangle:nth-child(3) {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.rectangle:nth-child(4) {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.rectangle:nth-child(5) {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
</style>