<template>
    <div v-if="showModal">
        <div class="modal d-block" id="rate_modal" tabindex="-1" aria-labelledby="rate_modalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">

                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close position-absolute" id="hideModal_" data-dismiss="modal" @click="$emit('showModal')" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="justify-content-center">
                            <h5 class="modal-title" id="rate_modalLabel">{{ $t('rate_order') }} </h5>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="mb-2" :key="product_id" v-for="{name,image,product_id}  in products">
                            <RateCard :media="image" :name="name" :id="product_id" @changeRate="rateProduct"/>
                        </div>

                    </div>
                    <div class="modal-footer justify-content-center p-1">
                        <button class="btn btn-success "  style="min-width: 120px"  @click="submitRate">{{$t('settings.save-changes')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {rateOrder} from "../services/products";
import RateCard from "./RateCard";
import {useToast} from "vue-toastification";


export default {
    props: ['showModal', 'products','order'],
    components: {
        RateCard,
    },
    data() {
        return {
            rates: [],
            toast: useToast(),
        }
    },

    methods: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        rateProduct(product_id,rate_val) {
            console.log(product_id,rate_val)
            let target = this.rates.find(item=>item.product_id === product_id)
            console.log(target)
            if(!target){
                this.rates.push({product_id,rate:rate_val})
            }else {
                target.rate = rate_val
            }
            console.log(this.rates)
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
       submitRate() {
           rateOrder({order_id: this.order,items: this.rates})
           .then(()=> {
               this.$emit('showModal')
           }).catch(() =>{
               this.toast.error(this.$t('error_msg'))
           })
       }
    }
}

</script>

<style scoped>
.close {
    right: 1rem;
}
[dir=rtl] .close {
    left: 1rem;
    right: unset;
}

.prod_img {
    height: 90px;
    width: 90px;
}
</style>