<template>
    <div class="card mb-2">
        <div class="d-flex  align-items-start " style="gap: 10px">
            <img :src="media" alt="" class="prod_img">
            <div class="d-flex py-2 flex-column justify-content-start">
                <p class="product-name font-weight-bold mb-1 mx-0">
                    {{ name }}
                </p>

                <star-rating :rtl="$i18n.locale === 'ar'" :rating="rate" @update:rating="updateRate" :star-size="25"
                             :rounded-corners="true" :increment="0.5"/>
            </div>
        </div>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
    props: ['media', 'name', 'id'],
    components: {
        StarRating
    },
    computed: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        APP_URL: function () {
            return process.env.VUE_APP_REMOTE_URL
        },
    },
    data() {
        return {
            rate: 0
        }
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        updateRate(rate) {
            this.$emit('changeRate', this.id, rate)
        }
    }
}
</script>

<style>
.prod_img {
    width: 80px;
    height: 80px;
    object-fit: cover;
}
</style>