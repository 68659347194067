<template>
    <BaseLayout>
        <template #page-content>
            <BreadCrumb :current-page="$t('nav.cart')"/>
            <div class="container order-details">
                <section class="card setting-card" v-if="!loading">
                    <h2 class="text-center heading">{{ $t('order.order-details') }}</h2>
                    <div class="details-body">
                        <div class="row">
                            <div class="col-lg-7 col-12">
                                <h3>{{ $t('order.order-details') }}</h3>
                                <div class="table-responsive table-summery">
                                    <table class="table table-hover mb-0 tw-custom">
                                        <thead>
                                        <tr>
                                            <th>{{ $t('order.product-picture') }}</th>
                                            <th>{{ $t('order.product-name') }}</th>
                                            <th>{{ $t('order.quantity') }}</th>
                                            <th>{{ $t('order.total_price') }}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="product in order.OrdersProducts" :key="product.id">
                                            <td>
                                                <img :src="product.image" class="prod_img" :alt="product.name">
                                            </td>
                                            <td>{{ product.name }}</td>
                                            <td>{{ product.quantity }}</td>
                                            <td>{{ Math.round(product.price * product.quantity) }} {{
                                                    currency.coin.name
                                                }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <h3>{{ $t('order.shipping-address') }}</h3>
                                <div class="card-gray">
                                    <div class="address">
                                    <span>
                                        <img src="@/assets/img/address.png" alt="">
                                    </span>
                                        <div class="address-details">
                                            <p> {{ order.name }}</p>
                                            <div>
                                                <p>{{ order.address }}</p>
                                                <p> {{ order.mobile }}</p>
                                            </div>
                                            <p> {{ order.address }}</p>
                                        </div>
                                    </div>
                                </div>

                                <h3>{{ $t('order.invoice-details') }}</h3>
                                <div class="card-gray ">
                                    <div class="invoice">
                                        <div class="cart-summery-item">
                                            <p class="summery-title">{{ $t('order.cost') }}</p>
                                            <p class="summery-total">{{ order.amount }} {{ currency.coin.name }}</p>
                                        </div>
                                        <div class="cart-summery-item">
                                            <p class="summery-title">{{ $t('order.delivery') }}</p>
                                            <p class="summery-total">{{ order.delivery }} {{ currency.coin.name }}</p>
                                        </div>
                                        <div class="cart-summery-item">
                                            <p class="summery-title">{{ $t('order.tax') }}</p>
                                            <p class="summery-total">{{ order.tax }} {{ currency.coin.name }}</p>
                                        </div>

                                        <div class="cart-summery-item">
                                            <p class="summery-title">{{ $t('discount') }}</p>
                                            <p class="summery-total">{{ order.discount_amount }} {{
                                                    currency.coin.name
                                                }}</p>
                                        </div>

                                        <div class="cart-summery-item">
                                            <p class="summery-title">{{ $t('order.total') }}</p>
                                            <p class="summery-total">{{ order.total }} {{ currency.coin.name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-12">
                                <h3>{{ $t('order.order-details') }}</h3>
                                <div class="card-gray card-progress">
                                    <div class="order-progress"
                                         :class="{'step-done' : order.status !== 2 || order.stat === 4}">
                                        <div class="check">
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div class="progress-status">
                                            <h4>{{ $t('order.receipt-request') }}</h4>
                                            <time v-if="!loading">{{ orderStatusDate(1) }}</time>
                                        </div>
                                    </div>

                                    <div class="order-progress" v-if="order.status === 2">
                                        <div class="check" style="background-color: #dc3545;border-color: #dc3545">
                                            <i class="fas fa-times text-white"></i>
                                        </div>
                                        <div class="progress-status">
                                            <h4>{{ $t('order.canceled') }}</h4>
                                            <time v-if="!loading">{{ orderStatusDate(2) }}</time>
                                        </div>
                                    </div>

                                    <div class="order-progress" v-if="order.status === 4 ">
                                        <div class="check" style="background-color: #dc3545;border-color: #dc3545">
                                            <i class="fas fa-times text-white"></i>
                                        </div>
                                        <div class="progress-status">
                                            <h4>{{ $t('order.rejected') }}</h4>
                                            <time v-if="!loading">{{ orderStatusDate(4) }}</time>
                                        </div>
                                    </div>

                                    <div class="order-progress"
                                         :class="{'step-done' : (order.status >= 5)}"
                                         v-if="order.status !== 2 && order.status !== 4 ">
                                        <div class="check">
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div class="progress-status">
                                            <h4>{{ $t('order.order_accepted') }}</h4>
                                            <time v-if="!loading">{{ orderStatusDate(5) }}</time>
                                        </div>
                                    </div>

                                    <div class="order-progress"
                                         :class="{'step-done' : (order.status >= 6)}"
                                         v-if="order.status !== 2 && order.status !== 4 ">
                                        <div class="check">
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div class="progress-status">
                                            <h4>{{ $t('order.order-delivery') }}</h4>
                                            <time v-if="!loading">{{ orderStatusDate(6) }}</time>
                                        </div>
                                    </div>

                                    <div class="order-progress"
                                         :class="{'step-done' : order.status === 8 || order.status === 7}"
                                         v-if="order.status !== 2 && order.status !== 4">
                                        <div class="check">
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div class="progress-status">
                                            <h4>{{ $t('order.delivered') }}</h4>
                                            <time v-if="!loading">{{ orderStatusDate(7) }}</time>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <RateModal :show-modal="isShow && $route.name === 'OrderDetails'" :order="$route.params.id" :products="order.OrdersProducts"
                               @showModal="hideModal"/>

                </section>
                <Loader v-else/>
            </div>
        </template>
    </BaseLayout>
</template>

<script>
import BaseLayout from "@/containers/BaseLayout";
import BreadCrumb from "@/components/BreadCrumb";
import {showOrder} from "@/services/orders";
import Loader from "@/components/Loader";
import store from "@/store";
import RateModal from "../../components/RateModal";

export default {
    name: "OrderDetails",
    components: {RateModal, BreadCrumb, BaseLayout, Loader},
    data() {
        return {
            order: {},
            status: [],
            isShow: false,
            loading: false
        }
    },
    mounted() {
        this.getOrder();
    },
    unmounted() {
        document.body.classList.remove('modal-open')
        document.body.classList.remove('backdrop')
    },
    watch: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        isShow: function (newVal) {
            if (newVal === true) {
                document.body.classList.add('modal-open')
                document.body.classList.add('backdrop')
            } else {
                document.body.classList.remove('modal-open')
                document.body.classList.remove('backdrop')
            }
        },
    },
    computed: {
        currency: function () {
            return store.getters.getCurrency;
        },
    },
    methods: {
        getOrder() {
            this.loading = true;
            showOrder({order_id: this.$route.params.id})
                .then(res => {
                    console.log(res)
                    this.loading = false
                    this.order = res.data.Order
                    this.status = res.data.Order.order_status
                    if(!res.data.Order.is_review && res.data.Order.status === 7){
                        this.isShow = true
                    }
                })
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        orderStatusDate(id) {
            let matched = Object.assign({}, this.status.find(item => item.status === id))
            return Object.keys(matched).length ? `${matched.order_status_time} - ${matched.order_status_date}` : ''
        },
        hideModal() {
            this.isShow = false
        }
    }
}
</script>

<style>
.backdrop {
    /*position: relative;*/
}

.backdrop:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.56);
}
</style>